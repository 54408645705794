import React from "react";
import {Link} from "react-router-dom";
import {getVerificationLinkByCallToken} from "../../util/utils";

const VerificationPending = ({user, call, type}) => {

    return (
        user &&
        <div>
            <h2>Hi {user?.name},</h2>
            <p>
                {type === 'p' ? "You" : "Patient"} {type === 'p' ? "have" : "has"} not
                verified {type === 'p' ? "your" : "their"} details yet.
                This call can be established only after patient verification is completed.
                {type === 'p' && " Click on below button to verify your details first and visit the same link again " +
                "to establish a call."}
            </p>
            {
                type === 'p' &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Link to={getVerificationLinkByCallToken(call)} className={"btn btn-primary btn-sm"}>Verify your details</Link>
                </div>
            }

        </div>
    );
};

export default VerificationPending;
import React, {Component} from 'react';
import {Row, Col, Button} from "reactstrap";

class Summary extends Component {
    render() {
        let questions = this.props.questions.map((question, key) => (
            <Row key={key}>
                <Col md={this.props.type === 'p' ? 12 : 11} xs={this.props.type === 'p' ? 12 : 10}>
                    <div className={"question"}>
                        {+key + 1}. {question.label}
                    </div>
                    {
                        question.answer?.answer &&
                        <div className={"options"}>
                            Selected: <span>{" "}{question.answer?.answer}</span>
                        </div>
                    }
                    {
                        question.answer?.comments &&
                        <>
                            <div className={"remarks"}>
                                Remarks: <span> {question.answer?.comments} </span>
                            </div>
                        </>
                    }
                </Col>
                {
                    /* provide edit option for doctors */
                    this.props.type === 'd' &&
                    <Col md={1} xs={2} className={"edit-column"}>
                        <Button color={"success"} outline size={"sm"} onClick={() => this.props.editQuestion(key)}>
                            <i className="fa fa-edit"/>
                        </Button>
                    </Col>
                }
            </Row>
        ));
        return (
            <>
                <div className={"question-summary"}>
                    {questions}
                </div>
            </>
        );
    }
}

export default Summary;
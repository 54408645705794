export default class Request {

    static get = (url) => {
        return fetch(url).then(res => res.json())
    };

    static postJson = (url, payload) => {
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json());
    };

    static post = (url, payload) => {

    }

}
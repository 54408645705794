import React, {useState, useEffect} from 'react';
import Video from 'twilio-video';

// import {Button} from 'reactstrap';
import Participant from './Participant';
import Loader from "../Loader";

const Room = ({roomName, token, handleLogout, setError}) => {
    const [loading, setLoading] = useState(true);
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };
        // LINK : https://www.twilio.com/docs/video/api/rooms-resource
        Video.connect(token, {
            name: roomName,
            // recordParticipantsOnConnect: true,
            // type: 'peer-to-peer'
        }).then(room => {
            setRoom(room);
            setLoading(false);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        }).catch(error => {
            setError("An error occurred while connecting the call.");
            handleLogout(true);
        });

        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token]);

    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant}/>
    ));

    // Listen to the "beforeunload" event on window to leave the Room
    // when the tab/browser is being closed.
    // window.addEventListener('beforeunload', () => room.disconnect());

    // iOS Safari does not emit the "beforeunload" event on window.
    // Use "pagehide" instead.
    // window.addEventListener('pagehide', () => room.disconnect());

    return (
        <div className="room">
            {/*<div>
                <h2 style={{textAlign: 'left'}}>Room: {roomName}</h2>
            </div>
            <div>
                <Button onClick={(event) => handleLogout()} color="danger" size="sm">End Call</Button>
            </div>*/}
            <div className="local-participant">
                {
                    loading &&
                    <Loader/>
                }
                {
                    remoteParticipants.length !== 0 &&
                    remoteParticipants
                }
                {
                    remoteParticipants.length === 0 &&
                    room && (
                        <>
                            <div className={"wait-message"}>
                                Please wait, other participant will join the call soon.
                            </div>
                            <Participant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                            />
                        </>
                    )
                }
            </div>
            {/*<h3>Remote Participants</h3>*/}
            {/*<div className="remote-participants">
                {room ? (
                    <Participant
                        key={room.localParticipant.sid}
                        participant={room.localParticipant}
                    />
                ) : (
                    ''
                )}
            </div>*/}
        </div>
    );
};

export default Room;

import React, {Component} from "react";
import {Route, withRouter, Switch, Redirect} from "react-router-dom";
import {Container} from "reactstrap";
import Call from "./Call";
import NotFound from "../Components/Messages/NotFound";
import Verification from "./Verification";

class MainRoute extends Component {

    render() {
        const {match} = this.props;
        return (
            <Container>
                <Switch>
                    <Route path={`${match.url}call`} component={Call}/>
                    <Route path={`${match.url}error`} component={NotFound}/>
                    <Route path={`${match.url}verification/:call_id`} component={Verification}/>
                    
                    <Redirect to="/error"/>
                </Switch>
            </Container>
        );
    }

}

export default withRouter(MainRoute);
import React from "react";

export default function ButtonLoader(props) {
    return (typeof props.isLoading === 'undefined' || !props.isLoading) ?
        <React.Fragment>{props.label}</React.Fragment>
        :
        <div className="lds-ellipsis">
            <div/>
            <div/>
            <div/>
            <div/>
        </div>;
}

import React, {Component, Fragment} from 'react';
import connect from "react-redux";
import {Button} from "reactstrap";

import {apiUrl} from "../constants/api";

import AccessDeniedNotice from "../Components/Messages/AccessDeniedNotice";
import AllowAccessNotice from "../Components/Messages/AllowAccessNotice";
import Loader from "../Components/Loader";
// import Room from "../Components/Video/Room";
import ThankYouMessage from "../Components/Messages/ThankYouMessage";
import WelcomeMessage from "../Components/Messages/WelcomeMessage";
import Request from "../util/api.service";
import {convert24To12, formatDate} from "../util/utils";
import VerificationPending from "../Components/Messages/VerificationPending";
import RoomLayout from "../Components/Video/RoomLayout";

class Call extends Component {

    state = {
        validateTimeSlot: false,
        id: null,
        type: null,
        loading: true,
        user: null,
        call: null,
        token: null,
        error: null,
        mediaAccessDenied: false,
        connectCallConfirmed: false,
        callFinished: false,
        verificationPending: false,
        showCallButton: true
    };

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let call_token = '', id = null, type = null;
        if (params.has('id') && params.has('type')) {
            // InvalidCharacterError
            try {
                call_token = params.get('id');
                type = atob(params.get('type'));
                this.getCallDetails(call_token, type.toLowerCase());
            } catch (error) {
                this.setState({error: "Invalid link, please contact Quantum Team.", loading: false});
            }
        } else {
            this.setState({error: "Invalid link, please contact Quantum Team.", loading: false});
        }
    }

    getCallDetails = (call_token, type) => {
        if ((type !== 'p' && type !== 'd') || call_token === '' || call_token === undefined) {
            this.setState({error: "Invalid link, please contact Quantum Team.", loading: false});
            return false;
        }

        Request.get(`${apiUrl}api/call/${call_token}`)
            .then(data => {
                if (data?.status === "success") {
                    const call = data.call;
                    const user = type === 'p' ? call.patient : call.doctor;
                    const verificationPending = !call.verified_at;
                    let id = call?.id;
                    let scheduled_at = new Date(call.scheduled_at);
                    let now = new Date();
                    let showCallButton = true;
                    let error = null;
                    let callFinished = false;
                    if (this.state.validateTimeSlot && scheduled_at > now) {
                        showCallButton = false;
                        error = <span>
                            Your call has been scheduled at {formatDate(scheduled_at)} {convert24To12(scheduled_at)}.
                            This link can not be accessed before your call scheduled time.
                        </span>;
                    }
                    if (call.patient_confirmation) {
                        /* call already finished */
                        callFinished = true;
                    }
                    this.setState({
                        id,
                        user,
                        call,
                        call_token,
                        type,
                        loading: false,
                        verificationPending,
                        showCallButton,
                        callFinished,
                        error
                    });
                } else {
                    this.setState({error: "This link has been expired.", loading: false});
                }
            })
            .catch(error => this.setState({loading: false, error: "Something went wrong"}));
    };

    /**
     * Check if application has access to user's camera and microphone
     * if not prompt user asking for the permission.
     * If permission has already been denied then show user steps to
     * grant access permission again
     */
    getDeviceAccess = () => {
        navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then(stream => {
                stream.getTracks().forEach(track => track.stop());
                this.setState({connectCallConfirmed: true});
                this.getAuthToken();
            })
            .catch(err => this.setState({mediaAccessDenied: true}));
    }

    getAuthToken = () => {
        this.setState({loading: true});
        Request.get(`${apiUrl}api/token/${this.state.call_token}/${this.state.type}`)
            .then(data => {
                if (data?.status === "success") {
                    this.setState({call: data.call, token: data.token, loading: false});
                } else {
                    this.setState({error: data.message, loading: false});
                }
            })
            .catch(error => this.setState({error: "Something went wrong while connecting the call", loading: false}));
    };

    setError = (error) => {
        this.setState({error});
    };

    handleLogout = (withOutConfirm = false) => {
        if (withOutConfirm || window.confirm("Are you sure you want to disconnect this call?")) {
            const token = null, loading = false;
            this.setState({token, loading, callFinished: !withOutConfirm});
        }
    };

    render() {
        return (
            <Fragment>
                {
                    this.state.loading &&
                    <Loader/>
                }
                {
                    !this.state.loading &&
                    this.state.error &&
                    <div className={"error-message"}>{this.state.error}</div>
                }
                {
                    this.state.verificationPending &&
                    <VerificationPending user={this.state.user} call={this.state.call} type={this.state.type}/>
                }
                {
                    !this.state.loading &&
                    !this.state.verificationPending &&
                    !this.state.token &&
                    !this.state.callFinished &&
                    this.state.user &&
                    <>
                        <WelcomeMessage user={this.state.user}/>
                        {
                            !this.state.mediaAccessDenied ?
                                <AllowAccessNotice/>
                                :
                                <AccessDeniedNotice/>

                        }
                        <br/>
                        {
                            this.state.showCallButton &&
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Button color="primary" onClick={this.getDeviceAccess} size="sm">
                                    {"Connect Call"}
                                </Button>
                            </div>
                        }
                    </>
                }
                {
                    !this.state.loading &&
                    !this.state.token &&
                    this.state.callFinished &&
                    <ThankYouMessage/>
                }
                {
                    !this.state.loading &&
                    this.state.connectCallConfirmed &&
                    this.state.token &&
                    <RoomLayout call={this.state.call}
                                type={this.state.type}
                                token={this.state.token}
                                handleLogout={this.handleLogout}
                                setError={this.setError}/>
                }
                {/*{
                    !this.state.loading &&
                    <RoomLayout call={this.state.call}
                                type={this.state.type}
                                token={this.state.token}
                                handleLogout={this.handleLogout}
                                setError={this.setError}/>
                }*/}
            </Fragment>
        );
    }

}

export default Call;
export const formatDate = (dateObj) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month = monthNames[dateObj.getMonth()];
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
    return day + '-' + month + '-' + year;
};

export const getDateLabel = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (formatDate(today) === formatDate(date)) {
        return 'Today';
    } else if (formatDate(tomorrow) === formatDate(date)) {
        return 'Tomorrow';
    } else {
        return formatDate(date);
    }
};

export const convert12To24 = (time) => {
    let hours = Number(time.match(/^(\d+)/)[1]);
    let minutes = Number(time.match(/:(\d+)/)[1]);
    let AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && parseInt(hours) === 12) hours = hours - 12;
    let sHours = hours.toString();
    // let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    // if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours;
};

export const convert24To12 = (time) => {
    let hours = time.getHours(); // gives the value in 24 hours format
    let AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    let minutes = time.getMinutes().toString().length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    hours = hours.toString().length === 1 ? '0' + hours : hours;
    return hours + ":" + minutes + " " + AmOrPm;
};

export const getVerificationLink = (call) => {
    let base64_string = btoa(call.id);
    base64_string = base64_string.replace(/[=]+/, '');
    return '/verification/' + base64_string;
};

export const getVerificationLinkByCallToken = (call) => {
    let call_token = (call?.call_token);
    return '/verification/' + call_token;
};
import React, {Component} from 'react';
import {Button, Label, Input, FormGroup} from "reactstrap";
import {apiUrl, webSocketUrl} from "../../constants/api";
import Request from "../../util/api.service";

import Summary from "./Summary";

const ADD = 'ADD';
const UPDATE = 'UPDATE';

class DoctorView extends Component {

    state = {
        getConfirmationForEachQuestion: false,
        showSummaryInEnd: true,
        summaryVisible: false,
        summaryConfirmed: false,
        allQuestions: [],
        wsConnection: null,
        currentQuestion: null,
        currentSequence: 0,
        from: null,
        to: null,
        isAssessmentFinished: false,
        assessmentCompletedMessage: 'Health assessment completed.',
        actionType: ADD
    };

    componentDidMount() {
        this.initiateWebSocketConnection();
        // this.getFirstQuestion();
        this.getAllQuestions();
    }

    getAllQuestions = () => {
        Request.get(`${apiUrl}api/all-questions/${this.props.call.patient_id}`)
            .then(response => {
                if (response.status === 'success') {
                    let allQuestions = response.data;
                    let currentQuestion = null, currentSequence = 0;
                    for (let key in allQuestions) {
                        if (allQuestions.hasOwnProperty(key) && !allQuestions[key].answer) {
                            currentQuestion = allQuestions[key];
                            currentSequence = key;
                            break;
                        }
                    }
                    if (currentQuestion && currentSequence) {
                        let isLastQuestion = allQuestions.length === (+currentSequence + 1);
                        this.setState({currentQuestion, currentSequence, allQuestions, isLastQuestion});
                    } else {
                        let assessmentCompletedMessage = 'Your health assessment is completed.';
                        this.setState({isAssessmentFinished: true, assessmentCompletedMessage});
                    }
                }
            }).catch(error => error);
    };

    getFirstQuestion = () => {
        Request.get(`${apiUrl}api/first-question/${this.props.call.patient_id}/${this.state.currentSequence}`)
            .then(response => {
                if (response.status === 'success') {
                    let currentQuestion = response.nextQuestion;
                    let currentSequence = response.nextSequence;
                    let isLastQuestion = response.isLastQuestion;
                    currentQuestion.answer = null;
                    currentQuestion.comment = null;
                    currentQuestion.confirmed = false;
                    currentQuestion.toBeConfirmed = false;
                    this.setState({currentQuestion, currentSequence, isLastQuestion, loading: false});
                    this.sendMessage('currentQuestion', {currentQuestion, currentSequence, isLastQuestion});
                } else if (response.status === 'completed') {
                    this.setState({
                        isAssessmentFinished: true,
                        assessmentCompletedMessage: "Health assessment already completed."
                    });
                    this.finishAssessment(true);
                }
            }).catch(error => error);
    };

    getNextQuestion = () => {
        new Promise((resolve, reject) => {
            if (!this.state.getConfirmationForEachQuestion) {
                this.confirmSelection();
            }
            resolve();
        }).then(() => {
            if (this.state.actionType === ADD) {
                /**
                 * if answer is being added then load next question after submission
                 */
                let {currentSequence, allQuestions} = this.state;
                currentSequence++;
                let isLastQuestion = allQuestions.length === (currentSequence + 1);
                let currentQuestion = allQuestions[currentSequence];
                this.setState({currentQuestion, currentSequence, isLastQuestion, loading: false});
                this.sendMessage('currentQuestion', {currentQuestion, currentSequence, isLastQuestion});
            } else {
                /**
                 * if answer is being updated then show the updated summary after submission
                 */
                this.showSummary();
            }

        });
    };

    finishAssessment = (alreadyDone = false) => {
        this.setState({isAssessmentFinished: true, summaryVisible: false});
        this.sendMessage('finishAssessment', {alreadyDone: alreadyDone ? 1 : 0});
    };

    initiateWebSocketConnection = () => {
        let wsConnection = new WebSocket(`${webSocketUrl}channel/${this.props.call.id}?type=d&id=${this.props.call.doctor_id}`);
        wsConnection.onopen = this.webSocketOnOpen;
        wsConnection.onerror = this.webSocketOnError;
        wsConnection.onclose = this.webSocketOnClose;
        wsConnection.onmessage = this.webSocketOnMessage;
        this.setState({wsConnection, from: this.props.call.doctor_id, to: this.props.call.patient_id});
    };

    webSocketOnOpen = (Event) => {
    };

    webSocketOnClose = (CloseEvent) => {
    };

    webSocketOnMessage = (MessageEvent) => {
        let message = JSON.parse(MessageEvent.data)
        if (parseInt(message.to) === parseInt(this.state.from)) {
            if (typeof this[message.action] === 'function') {
                this[message.action](message.payload);
            }
        }
    };

    webSocketOnError = (Event) => {
    };

    sendMessage = (action, payload) => {
        let message = {
            action: action,
            payload: payload,
            from: this.state.from,
            to: this.state.to,
        };
        this.state.wsConnection.send(JSON.stringify(message));
    };

    confirmSelection = () => {
        let {currentQuestion, isLastQuestion} = this.state;
        currentQuestion.confirmed = true;
        this.setState({currentQuestion}, this.currentQuestion);
        if (this.state.showSummaryInEnd) {
            currentQuestion['patient_id'] = this.props.call.patient_id;
            currentQuestion['doctor_id'] = this.props.call.doctor_id;
            currentQuestion['last_question'] = isLastQuestion;
            Request.postJson(`${apiUrl}api/confirm-answer-selection`, currentQuestion)
                .then(response => response).catch(error => error);
        }
    };

    confirmSummary = () => {
        this.setState({summaryConfirmed: true});
    }

    currentQuestion = (payload) => {
        if (!this.state.isAssessmentFinished) {
            /* patient websocket has asked for current question details then send it to the patient */
            let {currentQuestion, currentSequence, isLastQuestion} = this.state;
            this.sendMessage('currentQuestion', {currentQuestion, currentSequence, isLastQuestion});
        } else {
            this.sendMessage('finishAssessment', {alreadyDone: 1});
        }
    };

    askForConfirmation = () => {
        let {currentQuestion} = this.state;
        currentQuestion.toBeConfirmed = true;
        this.setState({currentQuestion}, this.currentQuestion);
    };

    onInputChange = (event) => {
        let {currentQuestion} = this.state;
        currentQuestion.answer = currentQuestion.answer ? currentQuestion.answer : {};
        currentQuestion.answer[event.target.name] = event.target.value;
        currentQuestion.toBeConfirmed = false;
        currentQuestion.confirmed = false;
        this.setState({currentQuestion}, this.currentQuestion);
    };

    editQuestion = (sequence) => {
        let actionType = UPDATE;
        let currentQuestion = this.state.allQuestions[sequence];
        let currentSequence = sequence;
        let summaryVisible = false;
        let isLastQuestion = this.state.isLastQuestion;
        this.setState({actionType, currentQuestion, currentSequence, summaryVisible});
        this.sendMessage('currentQuestion', {currentQuestion, currentSequence, isLastQuestion});
        this.props.toggleMobileVideo(false);
    }

    showSummary = () => {
        new Promise((resolve, reject) => {
            this.confirmSelection();
            resolve();
        }).then(() => {
            this.setState({summaryVisible: true});
            this.props.toggleMobileVideo(true);
            this.sendMessage('showSummary');
        });
    };

    render() {
        return (
            <>
                {
                    this.state.currentQuestion &&
                    !this.state.isAssessmentFinished &&
                    !this.state.summaryVisible &&
                    <>
                        <div className={"question"}>
                            {+this.state.currentSequence + 1}. {this.state.currentQuestion.label}
                        </div>
                        <hr/>
                        <div className={"options"}>
                            {
                                this.state.currentQuestion.type === 'radio' ?
                                    <>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="answer" value={"Yes"}
                                                       checked={this.state.currentQuestion.answer?.answer === 'Yes'}
                                                       onChange={this.onInputChange}/>
                                                {' '}Yes
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="answer" value={"No"}
                                                       checked={this.state.currentQuestion.answer?.answer === 'No'}
                                                       onChange={this.onInputChange}/>
                                                {' '}No
                                            </Label>
                                        </FormGroup>
                                    </>
                                    :
                                    <Input type="text" name="answer" id="answer"
                                           value={this.state.currentQuestion.answer?.answer ?? ''}
                                           placeholder="Type your input" onChange={this.onInputChange}/>
                            }
                        </div>
                        <hr/>
                        <div className={"remarks"}>
                            <Label for="comment">Remarks (If any)</Label>
                            <Input type="textarea" name="comments" id="comments" rows={4}
                                   value={this.state.currentQuestion.answer?.comments ?? ''}
                                   onChange={this.onInputChange}/>
                        </div>
                        <div className={"action-buttons"}>
                            {
                                !this.state.currentQuestion.toBeConfirmed &&
                                this.state.getConfirmationForEachQuestion &&
                                <Button color={"success"} size={"sm"} onClick={this.askForConfirmation}
                                        disabled={!this.state.currentQuestion.answer?.answer}>
                                    <i className={"fa fa-check"}/> Get confirmation
                                </Button>
                            }
                            {" "}
                            {
                                (this.state.currentQuestion.confirmed || !this.state.getConfirmationForEachQuestion) &&
                                !this.state.isLastQuestion &&
                                <>
                                    {
                                        this.state.getConfirmationForEachQuestion &&
                                        <span>Selection confirmed by patient</span>
                                    }
                                    <Button color={"success"} size={"sm"} onClick={this.getNextQuestion}
                                            disabled={!this.state.currentQuestion.answer?.answer}>
                                        {
                                            this.state.actionType === ADD ?
                                                <><i className={"fa fa-arrow-right"}/> Next Question</> :
                                                <><i className={"fa fa-check"}/> Update</>
                                        }

                                    </Button>
                                </>
                            }
                            {
                                this.state.currentQuestion.confirmed &&
                                this.state.isLastQuestion &&
                                !this.state.showSummaryInEnd &&
                                <Button color={"success"} size={"sm"} onClick={this.finishAssessment}>
                                    <i className={"fa fa-check-circle-o"}/> Finish Assessment
                                </Button>
                            }
                            {
                                this.state.isLastQuestion &&
                                this.state.showSummaryInEnd &&
                                <Button color={"success"} size={"sm"} onClick={this.showSummary}
                                disabled={!this.state.currentQuestion.answer?.answer}>
                                    <i className={"fa fa-check"}/> Get confirmation
                                </Button>
                            }
                        </div>
                    </>
                }
                {
                    this.state.isAssessmentFinished &&
                    !this.state.summaryVisible &&
                    <div className={"assessment-complete"}>
                        <i className={"fa fa-check-circle-o"}/> <br/>
                        {this.state.assessmentCompletedMessage}
                    </div>
                }

                {
                    this.state.summaryVisible &&
                    <>
                        <Summary questions={this.state.allQuestions} type={'d'} editQuestion={this.editQuestion}/>
                        {
                            <div className={"action-buttons"}>
                                <Button color={"success"} size={"sm"} onClick={this.finishAssessment} disabled={!this.state.summaryConfirmed}>
                                    <i className={"fa fa-check-circle-o"}/> Finish Assessment
                                </Button>
                            </div>
                        }
                    </>
                }
            </>
        );
    }

}

export default DoctorView;
import React from "react";

const WelcomeMessage = ({user}) => {
    return (
        user &&
        <div>
            <h3>Hi {user?.name},</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <br/><br/>
                This website requires to access your microphone and camera in order to establish call
            </p>
        </div>
    );
};

export default WelcomeMessage;
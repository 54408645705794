import React, {Component} from 'react';
import {Router, Route, Switch} from "react-router-dom";

import {history} from "./util/history";
import Header from "./Components/Layout/Header";
import MainRoute from "./routes";

import './assets/css/App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';

class App extends Component {

    render() {
        return (
            <div className="app">
                <Header/>

                <Router history={history}>
                    <Switch>
                        <Route path="/" component={MainRoute}/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
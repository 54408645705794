import React from "react";
import {isMobile, isIOS, isChrome, isAndroid, isFirefox, isSafari, isMobileSafari} from "react-device-detect";

const AccessDeniedNotice = () => {
    let notice = "";
    if (isMobile) {
        // mobile browser
        if (isIOS) {
            // iOS
            if (isMobileSafari) {
                // safari
                notice = "Please follow below steps to allow access in your iOS safari browser";
            } else {
                // chrome
                notice = "Please follow below steps to allow access in your iOS chrome browser";
            }
        } else if (isAndroid) {
            // android
            notice = "Please follow below steps to allow access in your android chrome browser";
        }
    } else {
        // desktop browser
        if (isFirefox) {
            // firefox
            notice = "Please follow below steps to allow access in your desktop FireFox browser";
        } else if (isChrome) {
            // chrome
            notice = "Please follow below steps to allow access in your desktop chrome browser";
        } else if (isSafari) {
            // desktop safari
            notice = "Please follow below steps to allow access in your desktop safari browser";
        }
    }

    return (
        <>
            <div className={"error-message"}>Oops, user media device access denied.</div>
            <p style={{color: '#f0293e'}}>
                {notice}
            </p>
        </>
    );
};

export default AccessDeniedNotice;
import React from 'react';

import QuantumLogo from "../../assets/images/quantumlogo.jpg";

const Header = () => (
    <header>
        <img src={QuantumLogo} alt={"Logo"} className={"header-logo"}/>
    </header>
);

export default Header;
import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import {isMobile} from "react-device-detect";

import Room from "./Room";
import PatientView from "../HealthAssessment/PatientView";
import DoctorView from "../HealthAssessment/DoctorView";

class RoomLayout extends Component {

    state = {
        videoHiddenForMobile: true
    };

    toggleMobileVideo = (visible) => {
        this.setState({videoHiddenForMobile: visible});
    };

    render() {
        return (
            <>
                <Row>
                    <Col md={"6"} sm={"12"}>
                        {
                            process.env.NODE_ENV &&
                            process.env.NODE_ENV === "development" &&
                            <div className={"room-title"}>
                                {this.props.type === 'p' ? "Patient" : "Doctor"}
                            </div>
                        }
                        {/*<div className={"room-title"}>
                            Policy No.:
                            {this.props.call.twilio_room_name} {this.props.type === 'p' ? "Patient" : "Doctor"}
                        </div>*/}
                    </Col>
                    <Col md={"6"} sm={"12"}>
                        <Button color="danger" size="sm" className={"end-call"} onClick={(e) => {
                            this.props.handleLogout()
                        }} title={"End call"}><i className={"fa fa-power-off"}/> {isMobile ? "" : "End Call"}</Button>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={"5"} sm={"12"} xs={"12"}
                         className={this.state.videoHiddenForMobile ? "room-section mobile-hidden" : "room-section"}>
                        {/* video streaming section */}
                        <Room roomName={this.props.call.twilio_room_name}
                              token={this.props.token}
                              handleLogout={this.props.handleLogout}
                              setError={this.props.setError}/>
                        {/*<div className="room">
                            <div className="local-participant">
                                <div className="participant">
                                    <video autoPlay={true} loop={true} muted={true}>
                                        <source src="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4"
                                                type="video/mp4"/>
                                        <source src="http://www.exit109.com/~dnn/clips/RW20seconds_1.mp4"
                                                type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>*/}
                    </Col>
                    <Col md={"7"} sm={"12"} xs={"12"} className={"question-section"}>
                        {/* question and answer section */}
                        {
                            this.props.type.toLowerCase() === "p" ?
                                <PatientView call={this.props.call} handleLogout={this.props.handleLogout}
                                             toggleMobileVideo={this.toggleMobileVideo}/> :
                                <DoctorView call={this.props.call} handleLogout={this.props.handleLogout}
                                            toggleMobileVideo={this.toggleMobileVideo}/>
                        }
                    </Col>

                </Row>
            </>
        );
    }

}

export default RoomLayout;
import React, {Component} from "react";
import {Alert, Button, Row, Col, Label, Input} from 'reactstrap';
import Camera from 'react-html5-camera-photo';
import SignaturePad from "react-signature-canvas";

import Request from "../util/api.service";
import {formatDate} from "../util/utils";
import {apiUrl} from "../constants/api";

import Loader from "../Components/Loader";
import ButtonLoader from "../Components/ButtonLoader";
import TimeSlotSelect from "../Components/Verification/TimeSlotSelect";

import 'react-html5-camera-photo/build/css/index.css';

class Verification extends Component {

    signPad;

    state = {
        call_id: null,
        loading: true,
        error: null,
        user: null,
        takePicture: false,
        takePictureLabel: "Open Camera",
        pictureDataUri: null,
        signDataUri: null,
        selectedDate: null,
        selectedTimeSlot: null,
        educational_qualifications: null,
        occupation: null,
        verified: false,
        success: null,
        buttonLoader: false,
        currentStep: 1,
    };

    componentDidMount() {
        try {
            let call_token = (this.props.match.params.call_id);
            let error = null, loading = false;
            if (call_token === '' || call_token === undefined) {
                error = "Invalid link, please contact Quantum Team.";
                let call_id = null;
                this.setState({call_id, call_token, error, loading});
            } else {
                fetch(`${apiUrl}api/call/${call_token}`)
                    .then(res => res.json())
                    .then(data => {
                        if (data?.status === "success") {
                            let call = data?.call;
                            let user = call?.patient;
                            let call_id = parseInt(call?.id);
                            let success = null, verified = false;
                            if (call.time_slot && call.scheduled_at) {
                                success = "You have already completed your verification.";
                                verified = true;
                            }
                            this.setState({call_id, call_token, user, loading: false, success, verified});
                        } else {
                            this.setState({error: "This link has been expired.", loading: false});
                        }
                    })
                    .catch(error => this.setState({loading: false, error: "Something went wrong"}));
            }
        } catch (error) {
            this.setState({error: "Invalid link, please contact Quantum Team.", loading: false});
        }
    }

    toggleCamera = () => {
        this.setState({takePicture: !this.state.takePicture});
    };

    handleTakePhoto = (pictureDataUri) => {
        this.setState({pictureDataUri, takePicture: false, takePictureLabel: "Click another picture"});
    };

    handleSubmit = () => {
        this.setState({buttonLoader: true});
        const payload = {
            pictureDataUri: this.state.pictureDataUri,
            signDataUri: this.state.signDataUri,
            selectedDate: formatDate(this.state.selectedDate),
            selectedTimeSlot: this.state.selectedTimeSlot,
            call_id: this.state.call_id,
            educational_qualifications: this.state.educational_qualifications,
            occupation: this.state.occupation,
        };

        Request.postJson(`${apiUrl}api/verify`, payload)
            .then(data => {
                if (data?.status === "success") {
                    this.setState({verified: true, success: data.message, buttonLoader: false})
                } else {
                    this.setState({error: "An error occurred while verifying your details", buttonLoader: false});
                }
            }).catch(error =>
            this.setState({
                error: "An error occurred while verifying your details",
                buttonLoader: false
            })
        );
    };

    onInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    clearSignPad = () => {
        this.signPad.clear();
        this.setState({signDataUri: ""})
    };

    validateSign = () => {
        if (!this.signPad.isEmpty()) {
            const signDataUri = this.signPad.getTrimmedCanvas().toDataURL("image/png");
            this.setState({signDataUri});
        }
    }

    render() {
        return (
            <>
                {
                    this.state.loading && <Loader/>
                }
                {
                    !this.state.loading && this.state.error &&
                    <Row>
                        Error
                    </Row>
                }
                {
                    !this.state.loading && this.state.user &&
                    <>
                        {
                            this.state.verified &&
                            <Row>
                                <Alert color="success" style={{width: '100%'}}>
                                    {this.state.success}
                                </Alert>
                            </Row>
                        }
                        <Row className={"mb-3"}>
                            <span className={this.state.takePicture ? "mobile-hidden" : ""}>
                                <b>Hi, {this.state.user?.name} </b><br/>
                                {
                                    /*!this.state.verified &&*/
                                    <>
                                        Please verify your details and select a time slot to schedule a call. <br/>
                                        This website needs to access your device camera to take a live picture for
                                        verification
                                        purpose. <br/>
                                        If and when asked allow access to camera. Click on the button below to take a
                                        picture.
                                    </>
                                }

                            </span>
                        </Row>
                        {
                            !this.state.verified &&
                            <>
                                <Row className={"mb-3"}>
                                    <Col sm={12} md={12} style={{textAlign: 'center'}}
                                         className={this.state.currentStep !== 1 ? "hidden" : ""}>
                                        <h4>Step 1. Click a live picture</h4>
                                        <Button color="primary" onClick={this.toggleCamera} size="sm">
                                            <i className={"fa fa-camera"}/> {this.state.takePictureLabel}
                                        </Button>
                                        {
                                            this.state.pictureDataUri &&
                                            !this.state.takePicture &&
                                            <div className={"col-md-12"}>
                                                <img src={this.state.pictureDataUri} alt=""
                                                     className={"image-preview"}/>
                                            </div>
                                        }
                                        {
                                            this.state.takePicture &&
                                            <div className={"camera-container"}>
                                                <div className={"camera-close"} onClick={this.toggleCamera}>
                                                    <i className={"fa fa-close"}/>
                                                </div>
                                                <Camera onTakePhoto={this.handleTakePhoto} isImageMirror={true}/>
                                            </div>
                                        }
                                        {
                                            this.state.pictureDataUri &&
                                            !this.state.takePicture &&
                                            <div className={"col-md-12"}>
                                                <Button color="primary" onClick={() => this.setState({currentStep: 2})}
                                                        size="sm" className={"float-right"}>
                                                    <i className={"fa fa-arrow-right"}/> Next step
                                                </Button>
                                            </div>
                                        }
                                    </Col>

                                    <Col sm={12} md={12} style={{textAlign: 'center'}}
                                         className={this.state.currentStep !== 2 ? "hidden" : ""}>
                                        <h4>Step 2. Please sign in below box</h4>
                                        <Row>
                                            <div className="signaturepad" onMouseUp={this.validateSign}>
                                                <SignaturePad
                                                    penColor="black"
                                                    clearOnResize={false}
                                                    canvasProps={{ height: 200, width: 400 }}
                                                    ref={ref => this.signPad = ref}
                                                    className="signature-pad"
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className={"col-md-12"}>
                                                <Button color="danger"
                                                        onClick={() => this.setState({currentStep: 1})}
                                                        size="sm" className={"float-left"}>
                                                    <i className={"fa fa-arrow-left"}/> Previous step
                                                </Button>
                                                &nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.signDataUri &&
                                                    <Button color="danger"
                                                            onClick={this.clearSignPad}
                                                            size="sm" className={"float-left ml-2"}>
                                                        <i className={"fa fa-remove"}/> Clear signature
                                                    </Button>
                                                }
                                                {
                                                    this.state.signDataUri &&
                                                    <Button color="primary"
                                                            onClick={() => this.setState({currentStep: 3})}
                                                            size="sm" className={"float-right"}>
                                                        <i className={"fa fa-arrow-right"}/> Next step
                                                    </Button>
                                                }
                                            </div>
                                        </Row>
                                    </Col>

                                    <Col sm={12} md={12} style={{textAlign: 'center'}}
                                         className={this.state.currentStep !== 3 ? "hidden" : ""}>
                                        <h4>Step 3. Select day and time slot for call</h4>
                                        <Row>
                                            <Col sm={12} md={6} style={{margin: '0 auto'}}>
                                                <TimeSlotSelect onSelect={(selectedDate, selectedTimeSlot) => {
                                                    this.setState({selectedDate, selectedTimeSlot})
                                                }}/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <div className={"col-md-12"}>
                                                <Button color="danger"
                                                        onClick={() => this.setState({currentStep: 2})}
                                                        size="sm" className={"float-left"}>
                                                    <i className={"fa fa-arrow-left"}/> Previous step
                                                </Button>
                                                {
                                                    this.state.selectedDate && this.state.selectedTimeSlot &&
                                                    <Button color="primary"
                                                            onClick={() => this.setState({currentStep: 4})}
                                                            size="sm" className={"float-right"}>
                                                        <i className={"fa fa-arrow-right"}/> Next step
                                                    </Button>
                                                }
                                            </div>
                                        </Row>
                                    </Col>

                                    <Col sm={12} md={12}
                                         className={this.state.currentStep !== 4 ? "hidden" : ""}>
                                        <h4 style={{textAlign: 'center'}}>Step 4. Verify your details.</h4>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <Label for="name">Name</Label>
                                                <Input type="text" name="name" disabled id="name"
                                                       value={this.state.user.name}/>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Label for="email">Email</Label>
                                                <Input type="text" name="email" disabled id="email"
                                                       value={this.state.user.email}/>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Label for="mobile">Mobile</Label>
                                                <Input type="text" name="mobile" disabled id="mobile"
                                                       value={this.state.user.mobile}/>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Label for="dob">Date of birth</Label>
                                                <Input type="text" name="dob" disabled id="dob"
                                                       value={this.state.user.dob}/>
                                            </Col>

                                            <Col sm={12} md={6}>
                                                <Label for="educational_qualifications">Educational Qualifications</Label>
                                                <Input type="text" name="educational_qualifications"
                                                       autoComplete={"off"}
                                                       id="educational_qualifications" onChange={this.onInputChange}/>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Label for="occupation">Occupation</Label>
                                                <Input type="text" name="occupation" id="occupation"
                                                       autoComplete={"off"}
                                                       onChange={this.onInputChange}/>
                                            </Col>
                                        </Row>
                                        <Row className={"mt-3"}>
                                            <div className={"col-md-12"}>
                                                <Button color="danger"
                                                        onClick={() => this.setState({currentStep: 3})}
                                                        size="sm" className={"float-left"}>
                                                    <i className={"fa fa-arrow-left"}/> Previous step
                                                </Button>
                                                {
                                                    this.state.educational_qualifications && this.state.occupation &&
                                                    <Button color="success" onClick={this.handleSubmit} size="sm"
                                                            className={"float-right"}>
                                                        <ButtonLoader isLoading={this.state.buttonLoader}
                                                                      label={<span><i className={"fa fa-check"}/> Submit Details</span>}/>
                                                    </Button>
                                                }
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                {/*<Row>
                                    {
                                        this.state.pictureDataUri && this.state.selectedDate && this.state.selectedTimeSlot &&
                                        <Col sm={12} md={12} style={{textAlign: 'center'}}>
                                            <Button color="primary" onClick={this.handleSubmit} size="sm">
                                                <ButtonLoader isLoading={this.state.buttonLoader}
                                                              label={"Submit Details"}/>
                                            </Button>
                                        </Col>
                                    }
                                </Row>*/}
                            </>
                        }
                    </>
                }
            </>
        );
    }

}

export default Verification;
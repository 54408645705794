import React, {Component} from 'react';
import {Button} from "reactstrap";
import {apiUrl, webSocketUrl} from "../../constants/api";
import Request from "../../util/api.service";
import Summary from "./Summary";

class PatientView extends Component {

    state = {
        getConfirmationForEachQuestion: false,
        showSummaryInEnd: true,
        summaryVisible: false,
        summaryConfirmed: false,
        allQuestions: [],
        wsConnection: null,
        currentQuestion: null,
        currentSequence: 0,
        from: null,
        to: null,
        isAssessmentFinished: false,
        assessmentCompletedMessage: "Congratulations! Your health assessment is complete."
    };

    componentDidMount() {
        this.initiateWebSocketConnection();
        this.getAllQuestions();
    }

    getAllQuestions = () => {
        Request.get(`${apiUrl}api/all-questions/${this.props.call.patient_id}`)
            .then(response => {
                if (response.status === 'success') {
                    let allQuestions = response.data;
                    let currentQuestion = null, currentSequence = 0;
                    for (let key in allQuestions) {
                        if (allQuestions.hasOwnProperty(key) && !allQuestions[key].answer) {
                            currentQuestion = allQuestions[key];
                            currentSequence = key;
                            break;
                        }
                    }
                    if (currentQuestion && currentSequence) {
                        this.setState({currentQuestion, currentSequence, allQuestions});
                    } else {
                        let assessmentCompletedMessage = 'Your health assessment is completed.';
                        this.setState({isAssessmentFinished: true, assessmentCompletedMessage});
                    }
                }
            }).catch(error => error);
    };

    initiateWebSocketConnection = () => {
        let wsConnection = new WebSocket(`${webSocketUrl}channel/${this.props.call.id}?type=p&id=${this.props.call.patient_id}`);
        wsConnection.onopen = this.webSocketOnOpen;
        wsConnection.onerror = this.webSocketOnError;
        wsConnection.onclose = this.webSocketOnClose;
        wsConnection.onmessage = this.webSocketOnMessage;
        this.setState({wsConnection, from: this.props.call.patient_id, to: this.props.call.doctor_id});
    };

    webSocketOnOpen = (Event) => {
    };

    webSocketOnClose = (CloseEvent) => {
    };

    webSocketOnMessage = (MessageEvent) => {
        let message = JSON.parse(MessageEvent.data)
        if (parseInt(message.to) === parseInt(this.state.from)) {
            if (typeof this[message.action] === 'function') {
                this[message.action](message.payload);
            }
        }
    };

    webSocketOnError = (Event) => {
    };

    currentQuestion = (payload) => {
        /* if doctor.js has sent current question details then store it in component state */
        let {allQuestions} = this.state;
        let {currentQuestion, currentSequence, isLastQuestion} = payload;
        allQuestions[currentSequence] = currentQuestion;
        this.setState({currentQuestion, currentSequence, isLastQuestion, summaryVisible: false});
        this.props.toggleMobileVideo(false);
    }

    finishAssessment = (payload) => {
        let {assessmentCompletedMessage} = this.state;
        if (payload?.alreadyDone) {
            assessmentCompletedMessage = 'Your health assessment is completed.';
        }
        this.setState({isAssessmentFinished: true, assessmentCompletedMessage, summaryVisible: false});
    };

    sendMessage = (action, payload) => {
        let message = {
            action: action,
            payload: payload,
            from: this.state.from,
            to: this.state.to,
        };
        this.state.wsConnection.send(JSON.stringify(message));
    };

    confirmSelection = () => {
        let {currentQuestion, isLastQuestion} = this.state;
        currentQuestion.confirmed = true;
        this.setState({currentQuestion}, this.currentQuestion);
        currentQuestion['patient_id'] = this.props.call.patient_id;
        currentQuestion['doctor_id'] = this.props.call.doctor_id;
        currentQuestion['last_question'] = isLastQuestion;
        Request.postJson(`${apiUrl}api/confirm-answer-selection`, currentQuestion)
            .then(response => {
                this.sendMessage('confirmSelection', {});
            }).catch(error => error);
    };

    confirmSummary = () => {
        let payload = {call_id: this.props.call.id};
        Request.postJson(`${apiUrl}api/confirm-form-selection`, payload)
            .then(response => {
                this.sendMessage('confirmSummary', {});
            }).catch(error => error);

    };

    showSummary = () => {
        this.setState({summaryVisible: true});
        this.props.toggleMobileVideo(true);
    };

    render() {
        return (
            <>
                {
                    this.state.currentQuestion &&
                    !this.state.isAssessmentFinished &&
                    !this.state.summaryVisible &&
                    <>
                        <div className={"question"}>
                            {+this.state.currentSequence + 1}. {this.state.currentQuestion.label}
                        </div>
                        <hr/>
                        {
                            this.state.currentQuestion.answer?.answer &&
                            <div className={"options"}>
                                Selected: <span>{" "}{this.state.currentQuestion.answer?.answer}</span>
                            </div>
                        }
                        {
                            this.state.currentQuestion.answer?.comments &&
                            <>
                                <hr/>
                                <div className={"remarks"}>
                                    Remarks: <span> {this.state.currentQuestion.answer?.comments} </span>
                                </div>
                            </>
                        }
                        {
                            !this.state.currentQuestion.confirmed &&
                            this.state.currentQuestion.toBeConfirmed &&
                            <div className={"action-buttons"}>
                                <Button color={"success"} size={"sm"} onClick={this.confirmSelection}>
                                    <i className={"fa fa-check"}/> Confirm selection
                                </Button>
                            </div>
                        }
                    </>
                }
                {
                    this.state.isAssessmentFinished &&
                    !this.state.summaryVisible &&
                    <div className={"assessment-complete"}>
                        <i className={"fa fa-check-circle-o"}/> <br/>
                        {this.state.assessmentCompletedMessage}
                    </div>
                }
                {
                    this.state.summaryVisible &&
                    <>
                        <Summary questions={this.state.allQuestions} type={'p'}/>
                        {
                            !this.state.summaryConfirmed &&
                            <div className={"action-buttons"}>
                                <Button color={"success"} size={"sm"} disabled={this.state.summaryConfirmed}
                                        onClick={this.confirmSummary}>
                                    <i className="fa fa-check"/> Confirm
                                </Button>
                            </div>
                        }
                    </>
                }
            </>
        );
    }

}

export default PatientView;
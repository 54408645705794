import React, {Component} from "react";
import {Pagination, PaginationItem, PaginationLink, Label} from "reactstrap";
import {getDateLabel, convert12To24} from "../../util/utils";

class TimeSlotSelect extends Component {

    state = {
        datePagination: null,
        activeDate: null,
        selectedSlot: null,
        allTimeSlots: [
            "09:00 AM - 11:00 AM",
            "11:00 AM - 01:00 PM",
            "01:00 PM - 03:00 PM",
            "03:00 PM - 05:00 PM",
            "05:00 PM - 07:00 PM",
            "07:00 PM - 09:00 PM",
        ],
        disabledTimeSlots: []
    };

    componentDidMount() {
        const day1 = new Date();
        if (day1.getHours() >= 17 || day1.getDay() === 0) {
            // if it's already evening then don't show today for selection OR
            // if today is sunday then don't show today for selection
            day1.setDate(day1.getDate() + 1)
        }
        const day2 = new Date(day1);
        day2.setDate(day2.getDate() + 1);
        if (day2.getDay() === 0) {
            // if next day is sunday then skip and show monday fo selection
            day2.setDate(day2.getDate() + 1);
        }
        /*const day3 = new Date(day2);
        day3.setDate(day3.getDate() + 1);*/
        let datePagination = {
            "day1": day1,
            "day2": day2,
            // "day3": day3
        };
        this.setState({datePagination});
        this.changeDate(day1);
    }

    changeDate = (activeDate) => {
        let today = new Date();
        let disabledTimeSlots = [];
        if (today.getDate() === activeDate.getDate()) {
            today.setHours(today.getHours() + 2);
            disabledTimeSlots = this.state.allTimeSlots.filter((timeSlot) => {
                let timeSlotStart = timeSlot.split(' - ')[0];
                return today.getHours() >= convert12To24(timeSlotStart);
            });
        }
        this.setState({activeDate, selectedSlot: null, disabledTimeSlots});
        this.props.onSelect(null);
    };

    changeTimeSlot = (selectedSlot, isDisabled) => {
        if (!isDisabled) {
            this.setState({selectedSlot});
            this.props.onSelect(this.state.activeDate, selectedSlot);
        }
    };

    render() {
        return (
            <>
                {
                    this.state.datePagination &&
                    <div>
                        <Pagination className="date-pagination">
                            <PaginationItem active={this.state.activeDate === this.state.datePagination["day1"]}>
                                <PaginationLink onClick={() => {
                                    this.changeDate(this.state.datePagination["day1"])
                                }}>
                                    {getDateLabel(this.state.datePagination.day1)}
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem active={this.state.activeDate === this.state.datePagination["day2"]}>
                                <PaginationLink onClick={() => {
                                    this.changeDate(this.state.datePagination["day2"])
                                }}>
                                    {getDateLabel(this.state.datePagination.day2)}
                                </PaginationLink>
                            </PaginationItem>
                            {/*<PaginationItem
                                active={this.state.activeDate === this.state.datePagination["day3"]}>
                                <PaginationLink onClick={() => {
                                    this.changeDate(this.state.datePagination["day3"])
                                }}>
                                    {formatDate(this.state.datePagination.day3)}
                                </PaginationLink>
                            </PaginationItem>*/}
                        </Pagination>
                        <div className={"time-slot-list-wrapper"}>
                            <ul>
                                {
                                    this.state.allTimeSlots.map((timeSlot, key) => {
                                        let isDisabled = this.state.disabledTimeSlots.includes(timeSlot);
                                        return (
                                            <li className={isDisabled ? "disabled" : (timeSlot === this.state.selectedSlot ? "active" : "")}
                                                key={key}>
                                                <Label check onClick={() => {
                                                    this.changeTimeSlot(timeSlot, isDisabled)
                                                }}>
                                                    {/*<Input type={"radio"} name={"time_slot"} value={timeSlot}/>{' '}*/}
                                                    {timeSlot}
                                                </Label>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                }
            </>
        );
    }

}

export default TimeSlotSelect;
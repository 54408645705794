import React from "react";

const ThankYouMessage = () => (
    <div>
        Details provided by you has been noted. <br/>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
        <br/>
        <b>Thank you.</b>
    </div>
);

export default ThankYouMessage;
import React from "react";

const AllowAccessNotice = () => {
    return (
        <ul style={{padding: '0 25px'}}>
            <li>Allow access to microphone</li>
            <li>Allow access to camera</li>
        </ul>
    );
};

export default AllowAccessNotice;